<template>
  <div>
    <div>
      <a class="flix-html-a" href="#" @click.prevent="open = !open"><flixIcon id="bin" /> {{ entry.length }} {{ $tc('message.appointments', entry.length) }} {{ $t('message.delete') }}</a>
      <div v-if="open">&nbsp;<span class="flix-text-danger"><flixIcon id="warning-sign" /> {{ $t('message.allDataWillBeDeleted') }}</span> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="function () { setDelete() }">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-xs flix-btn-default" href="#" @click.prevent="open = false">{{ $t('message.no') }}</a></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    entry: Array,
    callback: Function
  },
  data () {
    return {
      open: false,
      variables: this.$getUserVariables(),
      deletions: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setDeleteToServer (data) {
      data.user = this.variables.user.md5_id
      this.$flix_post({
        url: 'booking/delete',
        data: data,
        callback: function () {
          this.deletions = this.deletions + 1
          if (this.deletions === Object.keys(this.entry).length) {
            this.open = false
            this.callback()
          }
        }.bind(this)
      })
    },
    setDelete () {
      this.deletions = 0
      var del = this.setDeleteToServer
      var deleteList = []
      Object.values(this.entry).forEach(function (entry) {
        deleteList.push(entry.ID + '')
      })

      var newBooking = JSON.parse(JSON.stringify(this.$store.getters.bookings))
      var ret = JSON.parse(JSON.stringify(newBooking))
      Object.keys(newBooking).forEach(function (assistent) {
        Object.keys(newBooking[assistent]).forEach(function (i) {
          if (deleteList.indexOf(newBooking[assistent][i].ID + '') !== -1) {
            del({
              ID: newBooking[assistent][i].ID,
              event_id: assistent
            })
            delete ret[assistent][i]
          }
        })
      })

      Object.keys(ret).forEach(function (assistent) {
        ret[assistent] = Object.values(ret[assistent])
      })

      this.$store.commit('setBookings', ret)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
